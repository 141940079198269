<template>
  <b-card-code title="">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Projektek</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/project/list-all')">Összes projekt</button>
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/project/list/archive')">Archivált projektek</button>
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/projects/create/full')">Projekt létrehozás</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'work_number'"
          class="text-nowrap"
        >
          <span @click="selectedData = props.row;$bvModal.show('project_data')" class="text-nowrap">{{ props.row.work_number }}</span>
        </div>

        <div v-else-if="props.column.field === 'price'">
          <span>{{ parseInt(props.row.price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}</span>
        </div>


        <!-- Column: Status -->
        <!--<span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>-->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!--<b-dropdown-item v-b-modal.changeStatusModal @click="changeStatus(props.row.id)">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Státusz állítás</span>
              </b-dropdown-item>-->
              <!--<b-dropdown-item @click="show(props.row.id)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Feladatok</span>
              </b-dropdown-item>-->
              <!--<b-dropdown-item @click="document(props.row.id)">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Dokumentum generálás</span>
              </b-dropdown-item>-->
              <b-dropdown-item v-show="checkRole()" @click="$router.push('/project/list/documents/'+props.row.id)">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Dokumentumok</span>
              </b-dropdown-item>
              <b-dropdown-item @click="$router.push('/project/list/invoice/'+props.row.id)">
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                <span>Számlák</span>
              </b-dropdown-item>
              <b-dropdown-item @click="arachive(props.row.id, props.row.bank_enabled)">
                <feather-icon
                  icon="FolderIcon"
                  class="mr-50"
                />
                <span>Archíválás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editProject(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="project_data" title="Projekt adatok" size="lg" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12" class="mt-1">
          <b>Projekt adatok</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Munka azonosító: <b>{{ selectedData.work_number }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Munka azonosító: <b>{{ selectedData.name }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Kezdés dátuma: <b>{{ selectedData.start_date }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Megrendelő: <b>{{ selectedData.customer }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Építési engedély szám: <b>{{ selectedData.building_permit }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Melyik céghez tartozik: <b>
            <span v-if="selectedData.work_number">
              <span v-if="selectedData.work_number.slice(0, 2) == 'ME'">Monolit Épszer Kft.</span>
              <span v-else>Monolit Profi Kft.</span>
            </span>
          </b>
        </b-col>
        <b-col lg="12" class="mt-1">
          Hosszú megnevezés: <b>{{ selectedData.description }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          <span v-if="selectedData.price">
            Vállalt összeg: <b>{{ selectedData.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} Ft</b>
          </span>
        </b-col>
        <b-col lg="6" class="mt-1">
          FAD: <b>{{ selectedData.fad }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Építés vezető: <b>{{ selectedData.foreman_name }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Előkészítő: <b>{{ selectedData.preparatory_name }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Vállalt befejezés napja: <b>{{ selectedData.end_date }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Biztosító: <b>{{ selectedData.insurance }}</b>
        </b-col>
        <b-col lg="6" class="mt-1">
          Biztosítás lejárta: <b>{{ selectedData.insurance_end_date }}</b>
        </b-col>
        <hr class="w-100 mt-3">
        <b-col lg="12" class="mt-1">
          <b>Mérföldkövek</b>
        </b-col>
        <table class="table mt-2">
          <thead>
            <tr>
              <th scope="col">Státusz</th>
              <th scope="col">Mérföldkő neve</th>
              <th scope="col">Teljesítés dátuma</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="milestone in selectedData.milestones" :key="milestone.id">
              <td>
                <feather-icon
                  v-if="milestone.done"
                  icon="PlusCircleIcon"
                  class="text-success"
                  size="16"
                />
                <feather-icon
                  v-else
                  icon="MinusCircleIcon"
                  class="text-danger"
                  size="16"
                />
              </td>
              <td>{{ milestone.name }}</td>
              <td>{{ milestone.date }}</td>
            </tr>
          </tbody>
        </table>
        <hr class="w-100 mt-2">
        <b-col lg="12" class="mt-1">
          <b>Alvállalkozók</b>
        </b-col>
        <table class="table mt-2">
          <thead>
            <tr>
              <th scope="col">Alvállalkozó</th>
              <th scope="col">Kezdés dátuma</th>
              <th scope="col">Befejezés dátuma</th>
              <th scope="col">Elvégzendő munka</th>
              <th scope="col">Megjegyzés</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="subcontractor_data in selectedData.subcontractor" :key="subcontractor_data.name">
              <td>{{ subcontractor_data.name }}</td>
              <td>{{ subcontractor_data.date }}</td>
              <td>{{ subcontractor_data.date_end }}</td>
              <td>{{ subcontractor_data.job }}</td>
              <td>{{ subcontractor_data.text }}</td>
            </tr>
          </tbody>
        </table>
        <hr class="w-100 mt-2">
        <b-col lg="12" class="mt-1">
          <b>Extra munka</b>
        </b-col>
        <table class="table mt-2">
          <thead>
            <tr>
              <th scope="col">Építés vezető</th>
              <th scope="col">Előkészítő</th>
              <th scope="col">Leírás</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="extra_data in selectedData.extra" :key="extra_data.name">
              <td>
                <feather-icon
                  v-if="extra_data.foreman"
                  icon="PlusCircleIcon"
                  class="text-success"
                  size="16"
                />
                <feather-icon
                  v-else
                  icon="MinusCircleIcon"
                  class="text-danger"
                  size="16"
                />
              </td>
              <td>
                <feather-icon
                  v-if="extra_data.preparatory"
                  icon="PlusCircleIcon"
                  class="text-success"
                  size="16"
                />
                <feather-icon
                  v-else
                  icon="MinusCircleIcon"
                  class="text-danger"
                  size="16"
                />
              </td>
              <td>{{ extra_data.extra_work }}</td>
            </tr>
          </tbody>
        </table>
        <hr class="w-100 mt-2">
        <b-col lg="12" class="mt-1">
          <b>Banki garancia</b>
        </b-col>
        <table class="table mt-2">
          <thead>
            <tr>
              <th scope="col">Garancia szám</th>
              <th scope="col">Kiadás dátuma</th>
              <th scope="col">Lejárat dátuma</th>
              <th scope="col">Kifizetés dátuma</th>
              <th scope="col">Összeg</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bank_data in selectedData.bank_data" :key="bank_data.start_date">
              <td>{{ bank_data.number }}</td>
              <td>{{ bank_data.start_date }}</td>
              <td>{{ bank_data.end_date }}</td>
              <td>{{ bank_data.pay_date }}</td>
              <td><span v-if="bank_data.price">{{ bank_data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} Ft</span></td>
            </tr>
          </tbody>
        </table>
        <hr class="w-100 mt-2">
        <b-col lg="12" class="mt-1">
          <b>Szerződés módosítások</b>
        </b-col>
        <table class="table mt-2">
          <thead>
            <tr>
              <th scope="col">Szerződés módosítás megnevezése</th>
              <th scope="col">Szerződés módosítás kelte</th>
              <th scope="col">Új dátum (ha van)</th>
              <th scope="col">Új vállalási összeg (ha van)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice_change in selectedData.invoice_changes" :key="invoice_change.contract_name">
              <td>{{ invoice_change.contract_name }}</td>
              <td>{{ invoice_change.contract_date }}</td>
              <td>{{ invoice_change.contract_new_date }}</td>
              <td><span v-if="invoice_change.contract_new_price">{{ invoice_change.contract_new_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }} Ft</span></td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BCol, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      selectedData: {},
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Munkaszám',
          field: 'work_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés munkaszám',
          },
        },
        {
          label: 'Projekt neve',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Megrendelő',
          field: 'customer',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés megrendelők között',
          },
        },
        {
          label: 'Kezdés dátuma',
          field: 'start_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés kezdésre',
          },
        },
        {
          label: 'Határidő',
          field: 'end_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés határidőre',
          },
        },
        {
          label: 'Vállalt összeg',
          field: 'price',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés összegre',
          },
        },
        {
          label: 'Építés vezető',
          field: 'foreman_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés Építés vezetőre',
          },
        },
        {
          label: 'Előkészítő',
          field: 'preparatory_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés Építés vezetőre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    checkRole() {
      return JSON.parse(localStorage.getItem('user')).role.includes('documents_list')
    },
    arachive(id, enabled) {
      if (enabled) {
        Swal.fire({
          title: 'Bank garancia ellenőrizve lett?',
          text: null,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Igen',
          cancelButtonText: 'Nem',
          customClass: {
            confirmButton: 'btn btn-primary ml-1',
            cancelButton: 'btn btn-danger mr-1',
          },
        }).then(result => {
          if (result.isConfirmed) {
            axios({
              method: 'put',
              url: `project/archive/${id}`,
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }).then(() => {
              this.getProjects()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sikeresen archiválva',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'A projekthez tartozó bank garancia hiányzik!',
                  icon: 'XOctagonIcon',
                  variant: 'danger',
                },
              })
            })
          }
        })
      } else {
        axios({
          method: 'put',
          url: `project/archive/${id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(() => {
          this.getProjects()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sikeresen archiválva',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'A projekthez tartozó bank garancia hiányzik!',
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        })
      }
    },
    editProject(id) {
      this.$router.push({ name: 'monolit.projects.edit', params: { id } })
    },
    getProjects() {
      axios({
        method: 'get',
        url: 'project/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
